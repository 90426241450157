export enum CheckboxMode {
  CheckMark = 'checkMark',
  Minus = 'minus',
  Square = 'square',
  Toggle = 'toggle',
}

export enum InputCheckboxSize {
  Small = 'small',
  Large = 'large',
}

export enum InputCheckboxColor {
  Primary = 'primary',
  Green = 'green',
  Purple = 'purple',
}
