import React, { forwardRef, InputHTMLAttributes } from 'react';
import { cn } from '@/lib/classNames';
import styles from './ToggleCheckbox.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  dataQA?: string;
  labelDataQa?: string;
}

export const ToggleCheckbox = forwardRef<
  HTMLInputElement,
  Props
>((props, ref) => {
  const {
    className,
    label,
    dataQA,
    labelDataQa,
    ...rest
  } = props;

  return (
    <label
      className={cn(className, styles.toggleWrapper)}
      data-qa={labelDataQa}
    >
      <input
        className={styles.checkbox}
        type="checkbox"
        ref={ref}
        data-qa={dataQA}
        data-checked={props.checked}
        {...rest}
      />

      <span className={styles.toggle}>

        <div className={styles.toggleCircle} data-qa='toggle-switch' />
      </span>

      {!!label && (
        <span className={styles.label}>
          {label}
        </span>
      )}
    </label>
  );
});
