import { LogLevels } from '@mate-academy/logger';
import { User } from '@/controllers/graphql/generated';
import { errorHandler } from '@/core/ErrorHandler';
import { getCookieValueByKey } from '@/controllers/analytics/analytics.utils/getCookieValueByKey';
import { logger } from '@/core/Logger';

type FBAnalyticsParams = Pick<User, 'fbp' | 'fbc' | 'fbclid'>;
export function getFBAnalyticsParams(): FBAnalyticsParams {
  const fbAnalyticsParams: FBAnalyticsParams = {
    fbp: '',
    fbc: '',
    fbclid: '',
  };

  try {
    fbAnalyticsParams.fbp = getCookieValueByKey('_fbp');
    fbAnalyticsParams.fbc = getCookieValueByKey('_fbc');
    fbAnalyticsParams.fbclid = getCookieValueByKey('_mate-fbclid');

    if (!fbAnalyticsParams.fbc && fbAnalyticsParams.fbclid) {
      const fbc = `fb.1.${Date.now().valueOf()}.${fbAnalyticsParams.fbclid}`;

      fbAnalyticsParams.fbc = fbc;
    }
  } catch (error) {
    errorHandler.captureException(error as Error, {
      logMessage: 'Error occurred while attempting to get the Facebook analytics parameters',
      logLevel: LogLevels.Error,
      logger: logger.child('getFBAnalyticsParams'),
    });
  }

  return fbAnalyticsParams;
}
