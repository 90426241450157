import React, { InputHTMLAttributes } from 'react';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';
import { CheckMarkCheckbox } from '@/components/ui/FormElements/FormInputs/InputCheckbox/CheckMarkCheckbox/CheckMarkCheckbox';
import {
  CheckboxMode,
  InputCheckboxColor,
} from '@/components/ui/FormElements/FormInputs/InputCheckbox';
import { ToggleCheckbox } from './ToggleCheckbox';
import { SquareCheckbox } from './SquareCheckbox';

interface BaseProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  dataQA?: string;
  labelDataQa?: string;
}

interface CustomProps {
  mode?: CheckboxMode;
}

interface CheckMarkCheckboxProps extends CustomProps {
  mode?: CheckboxMode.CheckMark | CheckboxMode.Minus;
  colorMode?: InputCheckboxColor;
}

interface SquareCheckboxProps extends CustomProps {
  mode: CheckboxMode.Square;
}

interface ToggleCheckboxProps extends CustomProps {
  mode: CheckboxMode.Toggle;
}

type CheckboxProps = CheckMarkCheckboxProps
  | SquareCheckboxProps
  | ToggleCheckboxProps;

type Props = BaseProps & CheckboxProps;

export const InputCheckboxUI = React.forwardRef<
  HTMLInputElement,
  Props
>((
  props,
  ref,
) => {
  const {
    mode = CheckboxMode.CheckMark,
    ...rest
  } = props;

  switch (mode) {
    case CheckboxMode.Square: {
      return (
        <SquareCheckbox
          ref={ref}
          {...rest}
        />
      );
    }

    case CheckboxMode.Toggle: {
      return (
        <ToggleCheckbox
          ref={ref}
          {...rest}
        />
      );
    }

    case CheckboxMode.CheckMark:
    case CheckboxMode.Minus:
    default: {
      return (
        <CheckMarkCheckbox
          ref={ref}
          mode={mode}
          {...rest}
          colorMode={'colorMode' in rest && rest.colorMode
            ? rest.colorMode
            : InputCheckboxColor.Primary}
        />
      );
    }
  }
});

export const InputCheckbox = withFinalFormController(
  { type: 'checkbox' },
)(InputCheckboxUI);
