import React, { forwardRef, InputHTMLAttributes } from 'react';
import { cn } from '@/lib/classNames';
import styles from './SquareCheckbox.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelDataQa?: string;
}

export const SquareCheckbox = forwardRef<
  HTMLInputElement,
  Props
>((props, ref) => {
  const {
    label,
    className,
    labelDataQa,
    ...rest
  } = props;

  return (
    <label
      className={cn(className, styles.checkboxContainer)}
      data-qa={labelDataQa}
    >
      <input
        className={styles.checkboxInput}
        type="checkbox"
        ref={ref}
        {...rest}
      />

      <span className={styles.label}>
        {label && (
          <span className={styles.content}>
            {label}
          </span>
        )}
      </span>
    </label>
  );
});
