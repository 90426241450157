import React, {
  DetailedHTMLProps, FC, InputHTMLAttributes, memo,
} from 'react';
import { cn } from '@/lib/classNames';
import FormStyles from '@/components/ui/FormElements/FormInputs/FormInputs.module.scss';
import { withFinalFormController } from '@/controllers/forms/forms.hocs/withFinalFormController';
import { makeValidator } from '@/controllers/forms/forms.validator';
import { isEmail } from '@/controllers/forms/forms.validator/validationRules';

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const InputEmailUI: FC<Props> = memo((
  props,
) => {
  const { className, ...rest } = props;

  return (
    <input
      className={cn(className, FormStyles.input)}
      type="email"
      autoComplete="email"
      {...rest}
    />
  );
});

export const InputEmail = withFinalFormController<string>(
  {
    validate: makeValidator(isEmail, 'validate_email'),
  },
)<Props>(InputEmailUI);
