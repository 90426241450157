import { useSubDomainContext } from '@/controllers/subDomain/subDomain.hooks/useSubDomainContext';
import { isGDPRCompliantDomain } from '@/controllers/subDomain/subDomain.utils/isGDPRCompliantDomain';
import { useAuthUser } from '@/controllers/user/user.hooks/useAuthUser';

interface Options {
  ssr?: boolean;
}

export const useConsentPrefilledState = (options: Options): boolean => {
  const { ssr = false } = options;
  const [authUser] = useAuthUser({ ssr });
  const { subDomain } = useSubDomainContext();

  const isDomainCompliant = isGDPRCompliantDomain(subDomain);

  return authUser?.newslettersSubscription
    || !isDomainCompliant;
};
