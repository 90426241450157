import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { I18N_CODES } from '@/lib/constants/general';
import { useTranslation } from '@/middleware/i18n';
import { typography } from '@/components/ui/typography';
import styles from '@/components/authentication/SignUp/SignUpElements/SignUpConsentNote/SignUpConsentNote.module.scss';
import { useGetPrivaciesDocs } from '@/controllers/landing/landing.helpers';

interface Props {
  buttonLabel: string;
}

export const SignUpConsentNote: FC<Props> = (props) => {
  const {
    buttonLabel,
  } = props;
  const { t } = useTranslation([I18N_CODES.loginPage]);

  const termsLinks = useGetPrivaciesDocs();

  return (
    <p
      className={cn(typography.platformTextSmall, styles.formAgreementTip)}
      data-qa="tos-consent-note"
      dangerouslySetInnerHTML={{
        __html: t(
          `${I18N_CODES.loginPage}:sign_up_consent`,
          {
            ...termsLinks,
            buttonLabel,
          },
        ),
      }}
    />
  );
};
