import cookie from 'cookie';

export function getCookieValueByKey(key: string) {
  const cookieValue = cookie.parse(document.cookie)[key];

  if (!cookieValue) {
    return undefined;
  }

  return cookieValue;
}
