import React, { forwardRef, InputHTMLAttributes, useMemo } from 'react';
import { cn } from '@/lib/classNames';
import { IconCheck } from '@/components/ui/icons/IconCheck';
import { IconMinus } from '@/components/ui/icons/IconMinus';
import { typography } from '@/components/ui/typography';
import styles from './CheckMarkCheckbox.module.scss';
import { CheckboxMode, InputCheckboxColor } from '../InputCheckbox.typedefs';

export const colorClassNames: Record<InputCheckboxColor, string> = {
  [InputCheckboxColor.Primary]: styles.checkboxPrimary,
  [InputCheckboxColor.Green]: styles.checkboxColorGreen,
  [InputCheckboxColor.Purple]: styles.checkboxColorPurple,
};

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  mode?: CheckboxMode;
  label?: string;
  colorMode: InputCheckboxColor;
  dataQA?: string;
  labelDataQa?: string;
}

export const CheckMarkCheckbox = forwardRef<
  HTMLInputElement,
  Props
>((props, ref) => {
  const {
    className,
    mode = CheckboxMode.CheckMark,
    label,
    colorMode,
    dataQA,
    labelDataQa,
    ...rest
  } = props;

  const CheckBoxIcon = useMemo(() => {
    if (mode === CheckboxMode.CheckMark) {
      return IconCheck;
    }

    return IconMinus;
  }, [mode]);

  return (
    <label
      className={cn(
        styles.checkBoxContainer,
        typography.platformTextMain,
        { [styles.checkBoxContainerDisabled]: props.disabled },
        colorClassNames[colorMode],
        className,
      )}
      data-qa={labelDataQa}
    >
      <input
        data-qa={dataQA}
        className={styles.checkBoxInput}
        type="checkbox"
        ref={ref}
        {...rest}
      />

      <span className={styles.checkboxDecoration} data-qa="open-question-answer-checkbox">
        <span className={styles.checkboxIcon}>
          <CheckBoxIcon />
        </span>
      </span>

      {label && (
        <span className={styles.label} data-qa="international-checkbox-label">
          {label}
        </span>
      )}
    </label>
  );
});
